import {
  useWallet,
  useConnection,
  WalletProvider,
  WalletContext,
  WalletContextState,
  ConnectionProvider,
} from "@solana/wallet-adapter-react";
// import { useWalletModal } from "@solana/wallet-adapter-react-ui";
import bs58 from "bs58";
import { useSearchParams } from "react-router-dom";
// import { queryTypes, useQueryState } from "next-usequerystate";
import { FC, useCallback } from "react";
import { toast } from "react-toastify";
import { convertOwner, displayPIN, useStorage } from "../utilsClaimMap";
import { Button, Link, useMediaQuery } from "@material-ui/core";
import { authenticateViaSignMessage } from "../walletFunctions";
import { track } from "../app";
var Buffer = require("buffer/").Buffer;

const notify = (notifyText: string, duration?: number) =>
  // console.log('toast');
  toast(notifyText, {
    position: "top-center",
    autoClose: duration ?? 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  export interface SignMessageProps {
    sendPin(pin: string): void;
    requestFullscreen(arg0: Boolean): void;
  }
export const SignMessage = (props: SignMessageProps) => {
  // let [searchParams, setSearchParams] = useSearchParams();
  const { getItem } = useStorage();
  const {sendPin, requestFullscreen} = props;
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { publicKey, signMessage } = useWallet();
  // sessionStorage.getItem("metaversolMapSig")
  // const connection = useConnection();
  // const thing = useWalletModal();
  // const walletAdapter = useWallet();

  const onClick = useCallback(async () => {
    try {
    requestFullscreen(false);
      const token = await getItem("metaversolMapSig");
      const sessionWallet = await getItem("metaversolMapWallet");
      console.log(token + sessionWallet);
      // `publicKey` will be null if the wallet isn't connected
      if (!publicKey) throw new Error("Wallet not connected!");
      if (
        token != null &&
        token != undefined &&
        token.length > 20 &&
        sessionWallet === publicKey.toBase58()
      ) {
        track('displayPin', {wallet: publicKey.toBase58()});
        displayPIN(sendPin, publicKey, token, signMessage);
        // notify(
        //   "You are authenticated! Click on a plot to claim or unclaim it. You can view your PIN and manage plot permissions via the Menu in the top right."
        // );
        const owner = convertOwner(publicKey.toBase58());

        // await setWallet(owner);
        // await setAuth('auth', { replace: true, state: true });
        // router.replace(
        //   {
        //     query: { wallet: owner, auth: true },
        //   },
        //   undefined,
        //   { shallow: true }
        // );
        // await mutate("/api");
        console.log(sessionStorage.getItem("metaversolMapSig"));
        return;
      }
      await authenticateViaSignMessage(sendPin, signMessage, publicKey);
      const owner = convertOwner(publicKey.toBase58());
      // await setWallet(owner);
    } catch (error: any) {
      notify(`Sign Message failed! ${error?.message}`);
      console.log("error", `Sign Message failed! ${error?.message}`);
    }
  }, [publicKey, notify, signMessage]);

  return (
    <Link
    // px="0px"
    // py="0px"
    // maxW={["120px", "120px", "120px", "160px", "160px"]}
    // maxW={["150px", "150px", "150px", "150px", "150px"]}
    // my={isMobile ? "4px" : "0"}
    // flexGrow={2}
    // mr={2}
    >
      <Button
        variant="contained"
        color="primary"
        style={{
          padding: "20px 30px",
          position: "absolute",
          zIndex: 100,
          height: "70px",
          width: "185px",
        }}
        onClick={onClick}
      >
        {!publicKey && <div>Connect Wallet</div>}
        {publicKey && <div>Authenticate</div>}
      </Button>
    </Link>
  );
};
