import { useEffect } from "react";
import * as anchor from "@project-serum/anchor";
import { Grid, Button } from "@material-ui/core";
import { useWallet, WalletContextState } from "@solana/wallet-adapter-react";
import {
  WalletMultiButton,
  WalletDisconnectButton,
} from "@solana/wallet-adapter-material-ui";
import RefreshIcon from "@material-ui/icons/Refresh";

export interface HomeProps {
  connection: anchor.web3.Connection;
  startDate: number;
  txTimeout: number;
  rpcHost: string;
  wallet: WalletContextState | undefined;
  setWallet(wallet: WalletContextState): void;
  handleOnClickRefreshUnity(): void;
}

const Home = (props: HomeProps) => {
  const wallet = useWallet();

  //updating wallet state

  useEffect(() => {
    if (props.wallet?.publicKey !== wallet.publicKey) {
      props.setWallet(wallet);
    }
  }, [wallet, props]);

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          style={{
            margin: 0,
            position: "absolute",
            zIndex: 100,
            transform: `translate(${-185}px, ${0}px)`,
            height: "70px",
          }}
          onClick={props.handleOnClickRefreshUnity}
        >
          <RefreshIcon />
        </Button>
        {!wallet.connected ? (
          <WalletMultiButton
            style={{
              padding: "20px 30px",
              position: "absolute",
              zIndex: 100,
              height: "70px",
              width: "185px",
            }}
          />
        ) : (
          <WalletDisconnectButton
            style={{
              padding: "20px 30px",
              position: "absolute",
              zIndex: 100,
              height: "70px",
              width: "185px",
            }}
          >
            {`Disconnect (${wallet.publicKey
              ?.toString()
              .slice(0, 4)}...${wallet.publicKey?.toString().slice(-4)})`}
          </WalletDisconnectButton>
        )}
      </Grid>
    </div>
  );
};

export default Home;
