import React, {
  Fragment,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import axios from "axios";
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";
import {
  ConnectionProvider,
  WalletProvider,
  WalletContextState,
} from "@solana/wallet-adapter-react";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import {
  ThemeProvider,
  createTheme,
  Grid,
  Button,
  Box,
} from "@material-ui/core";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import Home from "./Home";
import {
  awaitTransactionSignatureConfirmation,
  CandyMachineAccount,
  CANDY_MACHINE_PROGRAM,
  getCandyMachineState,
  mintOneToken,
} from "./candy-machine";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import LoginSolana from "./LoginSolana";
import "./app.css";
import "react-toastify/dist/ReactToastify.css";

import { notify } from "./utilsClaimMap";
import { ToastContainer } from "react-toastify";
import { SignMessage } from "./components/SignMessage";
import mixpanel from "mixpanel-browser";
import { useSearchParams } from "react-router-dom";
// or with require() syntax:
// const mixpanel = require('mixpanel-browser');

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production

export const track = (eventName: string, eventObject: object) => {
  mixpanel.init("718e29a3245ce8ffc2c3325b6e460242", { debug: true });
  // mixpanel.init("718e29a3245ce8ffc2c3325b6e460242", { debug: true });
  mixpanel.track(eventName, eventObject);
};
declare global {
  interface Window {
    solana?: any;
  }
}
const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const api = process.env.REACT_APP_API_URL;
const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;
const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(
  rpcHost ? rpcHost : anchor.web3.clusterApiUrl("devnet")
);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);
const txTimeoutInMilliseconds = 30000;
const unityPathEnv = process.env.REACT_APP_UNITY_DIR; // || "unitybuild/Build";

// This is the React component that will be rendering the Unity app.
function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGameOver, setIsGameOver] = useState(false);
  const [userName, setUserName] = useState("User");
  const unityPath2 = "https://shdw-drive.genesysgo.net/";
  const host = searchParams.get("host");
  const prefix = searchParams.get("prefix");
  let unityPath = "";
  if (host && prefix) {
    if (searchParams.get("host") === "s3") {
      unityPath =
        "https://s3.us-east-2.amazonaws.com/tribixbite/sx/" +
        searchParams.get("prefix") +
        "";
    } else {
      unityPath =
        unityPath2 +
        searchParams.get("host") +
        "/" +
        searchParams.get("prefix");
    }
  } else {
    unityPath = unityPathEnv + "/unitybuild";
  }
  console.log(`unityPath: ${unityPath}`);
  const {
    unityProvider,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    isLoaded,
    unload,
    requestPointerLock,
  } = useUnityContext({
    productName: "Moonrocks",
    companyName: "Moonshine Labs",
    loaderUrl: `${unityPath}.loader.js`,
    dataUrl: `${unityPath}.data`,
    frameworkUrl: `${unityPath}.framework.js`,
    codeUrl: `${unityPath}.wasm`,
  });

  const handleGameOver = useCallback((userName) => {
    setIsGameOver(true);
    setUserName(userName);
  }, []);

  const crossMintContainerRef = useRef<HTMLDivElement>(null);

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );
  // The app's state.
  const [isUnityMounted, setIsUnityMounted] = useState<boolean>(true);
  // const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [progression, setProgression] = useState<number>(0);
  const [crossMintClientId, setCrossMintClientId] = useState<string>("");
  const [collectionTitle, setCollectionTitle] = useState<string>("");
  const [collectionDescription, setCollectionDescription] =
    useState<string>("");
  const [collectionPhoto, setCollectionPhoto] = useState<string>("");
  const [nonce, setNonce] = useState<string>("");
  const [isUserMinting, setIsUserMinting] = useState(false);
  const [wallet, setWallet] = useState<WalletContextState>();

  // When the component is mounted, we'll register some event listener.
  useEffect(() => {
    addEventListener("canvas", handleOnUnityCanvas);
    addEventListener("progress", handleOnUnityProgress);
    addEventListener("loaded", handleOnUnityLoaded);
    addEventListener("RequestNFTData", handleOnUnityrequestNFTData);
    addEventListener("RequestStakeData", handleOnUnityRequestStakeData);
    addEventListener("HttpRequest", handleOnUnityHttpRequest);
    addEventListener("onMint", handleOnUnityMint);
    addEventListener("onCrossMint", handleOnCrossMint);
    addEventListener("onRequestWallet", handleOnRequestWallet);
    addEventListener("onRequestWalletInfo", handleOnRequestWalletInfo);
    addEventListener("ClaimStake", handleClaimStake);
    addEventListener("ExitEvent", handleExitEvent);

    //
    // When the component is unmounted, we'll unregister the event listener.
    return function () {
      removeEventListener("canvas", handleOnUnityCanvas);
      removeEventListener("progress", handleOnUnityProgress);
      removeEventListener("loaded", handleOnUnityLoaded);
      removeEventListener("RequestNFTData", handleOnUnityrequestNFTData);
      removeEventListener("RequestStakeData", handleOnUnityRequestStakeData);
      removeEventListener("HttpRequest", handleOnUnityHttpRequest);
      removeEventListener("onMint", handleOnUnityMint);
      removeEventListener("onCrossMint", handleOnCrossMint);
      removeEventListener("onRequestWallet", handleOnRequestWallet);
      removeEventListener("onRequestWalletInfo", handleOnRequestWalletInfo);
      removeEventListener("ClaimStake", handleClaimStake);
      removeEventListener("ExitEvent", handleExitEvent);
    };
  }, [addEventListener, removeEventListener, handleGameOver]);

  // Built-in event invoked when the Unity canvas is ready to be interacted with.
  function handleOnUnityCanvas(canvas: HTMLCanvasElement) {
    console.log("received handleOnUnityCanvas");
    const context = canvas.getContext("webgl");
    const contextAttributes = context?.getContextAttributes();
    console.log(contextAttributes);
    canvas.setAttribute("role", "unityCanvas");
  }

  // Built-in event invoked when the Unity app's progress has changed.
  function handleOnUnityProgress(progression: number) {
    setProgression(progression);
  }

  // Built-in event invoked when the Unity app is loaded.
  function handleOnUnityLoaded() {
    console.log("received loaded event");
    // setIsLoaded(true);
  }

  const restartGame = () => {
    sendMessage("NetworkGameplayStuff", "RestartGame");
  };

  const sendPin = (pin: string) => {
    sendMessage("MainPanel", "reactSendPin", pin);
  };

  function handleClickPastePin() {
    track("clickPastePin", { wallet: wallet?.publicKey?.toBase58() || "none" });
    navigator.clipboard
      .readText()
      .then(async (text) => {
        if (text.length === 8) {
          sendMessage("MainPanel", "reactSendPin", text);
          requestPointerLock();
          // requestFullscreen(true);
        } else
          notify(
            "First copy PIN to clipboard. It should be 8 characters long."
          );
        // `text` contains the text read from the clipboard
      })
      .catch((err) => {
        // maybe user didn't grant access to read from clipboard
        notify(
          "Copy PIN to clipboard to then try again. If there's a red clipboard icon in the address bar, click it to grant clipboard access.",
          4000
        );
        console.error(err);
      });
    // sendMessage("MainPanel", "reactSendPin", "A342BBC1");
  }

  async function handleExitEvent() {
    track("exitEvent", { wallet: wallet?.publicKey?.toBase58() || "none" });
    console.log("exit clicked");
    await unload();
    // handleOnClickRefreshUnity();
  }
  // Custom event invoked when the Unity app sends a message including something
  // it said.
  function handleOnUnityrequestNFTData(
    query: string,
    url: string,
    responseGameObjectName: string,
    responseGameObjectEventHandler: string,
    responseKey: string
  ) {
    requestNFTData(
      query,
      url,
      responseGameObjectName,
      responseGameObjectEventHandler,
      responseKey
    );
  }
  function handleOnUnityRequestStakeData(url: string, x: number, y: number) {
    RequestStakeData(url, x, y);
  }
  function RequestStakeData(url: string, x: number, y: number) {
    axios
      .get(`${api}/getStakes/${x}/${y}`)
      .then((r) => {
        const messageEnd = "MESSAGE_END";

        var responseString = JSON.stringify(r.data);
        var sendData = { stakes: r.data };
        var sendDataJson = JSON.stringify(sendData);
        console.log(responseString);
        sendMessage("Stakes", "StakeReceiver", sendDataJson);
      })
      .catch(
        (e) => console.dir(e)
        // sendMessage(
        //   responseString.responseGameObjectName,
        //   responseGameObjectEventHandler,
        //   JSON.stringify({ [`${responseKey}`]: "Query Failed " + e })
        // )
      );
  }

  function handleClaimStake(stakeID: string, walletAddr: string) {
    axios
      .get(`${api}/makeClaim/${stakeID}/${walletAddr}`)
      .then((r) => {
        const messageEnd = "MESSAGE_END";

        var responseString = JSON.stringify(r.data);

        var sendDataJson = JSON.stringify(responseString);
        console.dir(responseString);
        // sendMessage(
        //   "Stakes",
        //   "WalletReceiver",
        //   sendDataJson
        // )
      })
      .catch((e) => console.dir(e));
  }
  function handleOnRequestWalletInfo(walletAddr: string) {
    axios
      .get(`${api}/getWallet/${walletAddr}`)
      .then((r) => {
        var responseString = JSON.stringify(r.data);

        console.dir(responseString);
        sendMessage("Stakes", "WalletReceiver", responseString);
      })
      .catch((e) => console.dir(e));
  }

  async function handleOnCrossMint(
    ClientId: string,
    collectionTitle: string,
    collectionDescription: string,
    collectionPhoto: string
  ) {
    if (ClientId !== crossMintClientId) {
      setCollectionTitle(collectionTitle);
      setCollectionDescription(collectionDescription);
      setCollectionPhoto(collectionPhoto);
      setCrossMintClientId(ClientId);
    } else {
      let crossMintBtn: HTMLElement = crossMintContainerRef.current
        ?.firstChild as HTMLElement;
      crossMintBtn.click();
    }
  }

  function onMintFailure(message: string) {
    console.log(message);
    sendMessage("LocalPlayer", "SetResponseData", "False");
    sendMessage("MintingMachine", "SetResponseData", "False");
  }

  function onMintSuccess(message: string) {
    console.log(message);
    sendMessage("LocalPlayer", "SetResponseData", "True");
    sendMessage("MintingMachine", "SetResponseData", "True");
  }

  const getCandyMachineId = (
    REACT_APP_CANDY_MACHINE_ID: string
  ): anchor.web3.PublicKey | undefined => {
    try {
      const candyMachineId = new anchor.web3.PublicKey(
        REACT_APP_CANDY_MACHINE_ID!
      );

      return candyMachineId;
    } catch (e) {
      console.log("Failed to construct CandyMachineId", e);
      return undefined;
    }
  };

  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }
    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  async function handleOnUnityMint(REACT_APP_CANDY_MACHINE_ID: string) {
    console.log(REACT_APP_CANDY_MACHINE_ID);
    const candyMachineId = getCandyMachineId(REACT_APP_CANDY_MACHINE_ID);

    if (wallet?.connected && anchorWallet && candyMachineId) {
      const candyMachine = await getCandyMachineState(
        anchorWallet,
        candyMachineId,
        connection
      );
      if (candyMachine?.program && wallet?.publicKey) {
        try {
          setIsUserMinting(true);
          const mintTxId = (
            await mintOneToken(candyMachine, wallet?.publicKey)
          )[0];
          let status: any = { err: true };
          if (mintTxId) {
            status = await awaitTransactionSignatureConfirmation(
              mintTxId,
              txTimeoutInMilliseconds,
              connection,
              true
            );
          }
          if (status && !status.err) {
            console.log("Congratulations! Mint succeeded!");
            onMintSuccess("Congratulations! Mint succeeded!");
          } else {
            console.log("Mint failed! Please try again!");
            onMintFailure("Mint failed! Please try again!");
          }
        } catch (error: any) {
          let message = error.msg || "Minting failed! Please try again!";
          if (!error.msg) {
            if (!error.message) {
              message = "Transaction Timeout! Please try again.";
            } else if (error.message.indexOf("0x137")) {
              message = `SOLD OUT!`;
            } else if (error.message.indexOf("0x135")) {
              message = `Insufficient funds to mint. Please fund your wallet.`;
            }
          } else {
            if (error.code === 311) {
              message = `SOLD OUT!`;
              window.location.reload();
            } else if (error.code === 312) {
              message = `Minting period hasn't started yet.`;
            }
          }
          console.log(message);
        } finally {
          setIsUserMinting(false);
        }
      } else {
        console.log("NO candyMachine?.program && publicKey");
      }
    } else {
      console.log("NO walletConnected && anchorWallet && candyMachineId");
      console.log(wallet?.connected, anchorWallet, candyMachineId);
    }
  }

  function handleOnRequestWallet() {
    console.log(wallet?.publicKey?.toString());
    sendMessage("Stakes", "WalletSetter", wallet?.publicKey?.toString());
  }

  function handleOnUnityHttpRequest(
    url: string,
    method: string,
    data: string,
    responseGameObjectName: string,
    responseGameObjectEventHandler: string,
    responseKey: string
  ) {
    httpRequest(
      url,
      method,
      data,
      responseGameObjectName,
      responseGameObjectEventHandler,
      responseKey
    );
  }
  // Reloads unity
  const handleOnClickRefreshUnity = useCallback(async () => {
    track("Refresh Unity", { wallet: wallet?.publicKey?.toString() });
    // window.location.reload();
    restartGame();
    // if (isLoaded === true) {
    //   setIsLoaded(false);
    // }
    // setIsUnityMounted(!isLoaded);
    // console.log("reloading unity");
    // await unload();
  }, [isLoaded]);

  // useEffect(() => {
  //   if (!isUnityMounted) {
  //     handleOnClickRefreshUnity();
  //   }
  // }, [isUnityMounted, handleOnClickRefreshUnity]);

  useEffect(() => {
    if (crossMintClientId !== "") {
      let crossMintBtn: HTMLElement = crossMintContainerRef.current
        ?.firstChild as HTMLElement;
      crossMintBtn.click();
    }
  }, [crossMintClientId]);

  function requestNFTData(
    query: string,
    url: string,
    responseGameObjectName: string,
    responseGameObjectEventHandler: string,
    responseKey: string
  ) {
    axios
      .post(url, {
        query: query,
      })
      .then((r) => {
        const messageEnd = "MESSAGE_END";
        var responseString = JSON.stringify(r.data.data);
        var maxChunkSize = 10000;
        var numChunks = Math.ceil(responseString.length / maxChunkSize);
        console.log("pushing " + numChunks + " chunks");
        for (var i = 0; i < numChunks; i++) {
          var chunk = responseString.slice(
            i * maxChunkSize,
            (i + 1) * maxChunkSize
          );
          sendMessage(
            responseGameObjectName,
            responseGameObjectEventHandler,
            JSON.stringify({
              key: `${responseKey}`,
              response: chunk,
            })
          );
        }
        sendMessage(
          responseGameObjectName,
          responseGameObjectEventHandler,
          JSON.stringify({
            key: `${responseKey}`,
            response: messageEnd,
          })
        );
        console.log(r);
      })
      .catch((e) =>
        sendMessage(
          responseGameObjectName,
          responseGameObjectEventHandler,
          JSON.stringify({ [`${responseKey}`]: "Query Failed " + e })
        )
      );
  }

  function httpRequest(
    url: string,
    method: string,
    data: string,
    responseGameObjectName: string,
    responseGameObjectEventHandler: string,
    responseKey: string
  ) {
    axios({
      method: method === "post" ? "post" : "get",
      url: url,
      data: data,
    })
      .then((r) => {
        console.log(
          JSON.stringify({ key: `${responseKey}`, response: r.data })
        );
        sendMessage(
          responseGameObjectName,
          responseGameObjectEventHandler,
          JSON.stringify({
            key: `${responseKey}`,
            response: JSON.stringify(r.data),
          })
        );
        console.log(r);
      })
      .catch((e) =>
        sendMessage(
          responseGameObjectName,
          responseGameObjectEventHandler,
          JSON.stringify({ [`${responseKey}`]: "Query Failed " + e })
        )
      );
  }

  const handleOnClickFullscreen = () => {
    track("Fullscreen", { wallet: wallet?.publicKey?.toString() });
    requestPointerLock();
    requestFullscreen(true);
  };

  // This is the React component that will be rendering the Unity app.
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} autoConnect>
              <WalletDialogProvider>
                {wallet?.connected && (
                  <>
                    <SignMessage
                      sendPin={sendPin}
                      requestFullscreen={requestFullscreen}
                    />
                    {isLoaded && (
                      <Grid container justify="center">
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            padding: "20px 30px",
                            position: "absolute",
                            zIndex: 100,
                            height: "30px",
                            width: "350px",
                            justifyContent: "center",
                            alignSelf: "center",
                          }}
                          onClick={handleClickPastePin}
                        >
                          Paste PIN from Clipboard
                        </Button>
                      </Grid>
                    )}
                  </>
                )}
                {/* <LoginSolana setNonce={setNonce} /> */}

                <Home
                  connection={connection}
                  startDate={startDateSeed}
                  txTimeout={txTimeoutInMilliseconds}
                  rpcHost={rpcHost}
                  wallet={wallet}
                  setWallet={setWallet}
                  handleOnClickRefreshUnity={handleOnClickRefreshUnity}
                />
              </WalletDialogProvider>
            </WalletProvider>
          </ConnectionProvider>
          <Grid
            container
            justifyContent="flex-end"
            style={{
              padding: "15px 0px",
            }}
          ></Grid>
          {/* Some buttons to interact */}
        </Grid>
        {/* CrossMint Button, hidden */}
        <div ref={crossMintContainerRef}>
          <CrossmintPayButton
            style={{ display: "none", height: "0px" }}
            onClick={() => void 0}
            collectionTitle={collectionTitle}
            collectionDescription={collectionDescription}
            collectionPhoto={collectionPhoto}
            clientId={crossMintClientId}
          />
        </div>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={12}
        >
          <Box sx={{ width: "77%" }}>
            <div className="wrapper">
              {/* Introduction text */}

              {/* The Unity container */}
              {isUnityMounted === true && (
                <div className="unity-container">
                  {/* The loading screen will be displayed here. */}
                  {isLoaded === false && (
                    <div className="loading-overlay">
                      <div className="progress-bar">
                        <div
                          className="progress-bar-fill"
                          style={{ width: loadingProgression * 100 + "%" }}
                        />
                      </div>
                    </div>
                  )}
                  {/* The Unity app will be rendered here. */}
                  <Fragment>
                    <Unity
                      className="unity-canvas"
                      unityProvider={unityProvider}
                    />
                  </Fragment>
                </div>
              )}
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  onClick={handleOnClickFullscreen}
                  style={{ padding: "2px", width: "100%" }}
                >
                  <FullscreenIcon fontSize="large" color="primary" />
                </Button>
              </Grid>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default App;
