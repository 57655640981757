import { convertOwner, displayPIN, notify } from "./utilsClaimMap";
import { sign } from "tweetnacl";
import { PublicKey } from "@solana/web3.js";
import { track } from "./app";

export async function authenticateViaSignMessage(sendPin: (pin: string) => void, signMessage: ((message: Uint8Array) => Promise<Uint8Array>) | undefined, publicKey: PublicKey) {
    try {
      if (!publicKey) throw new Error("Wallet not connected!");
        // `signMessage` will be undefined if the wallet doesn't support it
      if (!signMessage)
      throw new Error("Wallet does not support message signing!");
    const apiNonce = await (
      await fetch(`${process.env.REACT_APP_API_URL}/nonce?wallet=${publicKey}`)
    ).json();
    //   console.dir(apiNonce);
    const nonce = await apiNonce.nonce;
    console.log(`apinonce is`);
    console.dir(nonce);
    if (!(nonce.length > 0)) throw new Error("Nonce not found!");
    // Encode nonce as bytes
    const message = new TextEncoder().encode(await nonce);
    // Sign the bytes using the wallet
    track('signMessageRequest', {wallet: publicKey.toBase58()});
    const signature = await signMessage(message);
    // Verify that the bytes were signed using the private key that matches the known public key
    if (!sign.detached.verify(message, signature, publicKey.toBytes()))
      throw new Error("Invalid signature!");
    let sig = Buffer.from((await signature).buffer).toString("base64");
    const saltKey = await (
      await fetch(`${process.env.REACT_APP_API_URL}/nonce`, {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wallet: publicKey,
          nonce: nonce,
          signature: sig,
        }),
      })
    ).json();
    // if (!(storeSig.length > 0)) throw new Error("Nonce not found!");
    if (!saltKey.success) throw new Error("Apologies, there was an error!");
    if (saltKey.saltKey.length > 0 && publicKey.toBase58().length > 20) {
      // notify(saltKey.saltKey);
      console.dir(saltKey);
      track('signMessageSuccess', {wallet: publicKey.toBase58()});
      sessionStorage.setItem("metaversolMapSig", saltKey.saltKey);
      sessionStorage.setItem("metaversolMapWallet", publicKey.toBase58());
      displayPIN(sendPin, publicKey, saltKey.saltKey, signMessage);
      // notify("You have successfully authenticated! View your PIN and manage plot permissions by clicking the top right menu button.");
      return saltKey.saltKey;
    } else throw new Error("Salt key not found!");
    // notify("Sign message successful! " + bs58.encode(signature));
    console.log("signature:");
    console.dir(signature);
    }
    catch (error: any) {
        notify(`Sign Message failed! ${error?.message}`);
        console.log("error", `Sign Message failed! ${error?.message}`);
      }
    };