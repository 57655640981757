import { StrictMode } from "react";
import { render } from "react-dom";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app";
import reportWebVitals, { sendToVercelAnalytics } from "./report-web-vitals";
import "./index.css";

const rootElement = document.getElementById("root");

// renders the app onto the document
render(
  <StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </StrictMode>,
  rootElement
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('rootoo'),
// );
// enable measuring performance of the app onto the console
reportWebVitals(console.log);
reportWebVitals(sendToVercelAnalytics);