import * as util from "util";
import { ToastContainer, toast } from "react-toastify";

import { useState, useEffect } from "react";
import { PublicKey } from "@solana/web3.js";
import { authenticateViaSignMessage } from "./walletFunctions";
import { MessageSignerWalletAdapterProps } from "@solana/wallet-adapter-base";

export const notifyWithClick = (notifyClickText: string, notifyClickFunction: () => Promise<void>, duration?: number) => {
  toast(
    notifyClickText,
    {
      position: "top-center",
      closeOnClick: true,
      autoClose: duration ?? 6000,
      hideProgressBar: false,
      closeButton: true,
      draggable: true,
      onClick: notifyClickFunction,
    }
  );
};
export const notify = (notifyText: string, duration?: number) =>
  // console.log('toast');
  toast(notifyText, {
    position: "top-center",
    autoClose: duration ?? 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
export const displayPIN = async (
  sendPin: (pin: string) => void,
  publicKey: PublicKey,
  token: string,
  signMessage: MessageSignerWalletAdapterProps['signMessage'] | undefined
) => {
  const owner = publicKey.toBase58();
  console.log(token);
  try {
    const retrievePIN = await (
      await fetch(`${process.env.REACT_APP_API_URL}/manage`, {
        method: "POST",
        headers: {
          token: token,
          wallet: owner,
          // "Content-Type": "application/json",
        },
        body: JSON.stringify({
          task: "retrievePIN",
        }),
      })
    ).json();
    console.dir(retrievePIN);
    console.log('got here')
    if (retrievePIN.pin.length < 1) {
      const newToken = await authenticateViaSignMessage(sendPin, signMessage, publicKey);
      // await displayPIN(publicKey, newToken, signMessage);
      return;
    };
    // sendPin(retrievePIN.pin.toUpperCase());
    notifyWithClick(
      `Your secret PIN has been generated successfully. Use it to authenticate in-game. Click this message to copy it to your clipboard.`, async () => {
            await navigator.clipboard.writeText(retrievePIN.pin.toUpperCase());
      }, 10000
    );
  } catch (e) {
    try {
      console.log('error');
      const newToken = await authenticateViaSignMessage(sendPin, signMessage, publicKey);
      await displayPIN(sendPin, publicKey, newToken, signMessage);
    } catch (e) {
      return notify(
        "Please connect your wallet and authenticate by clicking the Authenticate button on the main map page ('Home') to continue.",
      );
    }
  }
  console.log("displayPIN");
};
export const fetcher = (url: string) =>
  fetch(url).then((res) => {
    console.dir(url);
    return res.json();
  });
// const fetcher = async (url) => await axios.get(url).then((res) => res.data);

export const registeredPlayers = {
  DoZ1tbFkx663Pt8RUuw5jzCeypUKQsehXsfuezmmeXrt: "tribixbite",
  "3VweQdBxWPYJMVS46a11ZKRG6weEV2sRAX5yQupgee7U": "future3000",
  "2CHq3h1oWcxnqGSPXnBBnwNdFwhwDeMteQv9GDzMPzXb": "METAVERSOL",
  "7A4dcnp7qjtLKSg8np5ViDHJ81bAhaCctXBTGzAeo7Ag": "Fickle_Ent",
  "EvYUS4zTtQuNfDZXXwk6vDF9tEj7VbA4jygsypysUyoK": "qiwave",
  "3HS3mjdXm8yiwbX7uUxKr2KuwLCphaQSx75rFXzFEi6z": "00Smurf",
  "9qnLnCVJ5E5BUSVcCo9en2DnDyJkuFkbAecRhUaxYmrf": "FletcherSyntax",
  "9JKyDfGgWYXyLAsB6PF3PH4MPYV6QwAGty6ya66v9Zh6": "andycustom",
};
export function shortenWallet(wallet: string) {
    if (registeredPlayers[wallet as keyof typeof registeredPlayers]) {
        return registeredPlayers[wallet as keyof typeof registeredPlayers];
      }
  if (wallet && wallet.length > 14) {
    return wallet.substring(0, 4) + "..." + wallet.substring(wallet.length - 4);
    //   return wallet.slice(0, 8) + "...";
  }
  return wallet;
}
export function convertOwner(owner: string) {
  if (registeredPlayers[owner as keyof typeof registeredPlayers]) {
    return registeredPlayers[owner as keyof typeof registeredPlayers];
  }
  return owner;
}

type StorageType = "session" | "local";
type UseStorageReturnValue = {
  getItem: (key: string, type?: StorageType) => string;
  setItem: (key: string, value: string, type?: StorageType) => boolean;
  removeItem: (key: string, type?: StorageType) => void;
};

export const useStorage = (): UseStorageReturnValue => {
  const storageType = (type?: StorageType): "localStorage" | "sessionStorage" =>
    `${type ?? "session"}Storage`;

  const isBrowser: boolean = ((): boolean => typeof window !== "undefined")();

  const getItem = (key: string, type?: StorageType): string => {
    return isBrowser ? window[storageType(type)][key] : "";
  };

  const setItem = (key: string, value: string, type?: StorageType): boolean => {
    if (isBrowser) {
      window[storageType(type)].setItem(key, value);
      return true;
    }

    return false;
  };

  const removeItem = (key: string, type?: StorageType): void => {
    window[storageType(type)].removeItem(key);
  };

  return {
    getItem,
    setItem,
    removeItem,
  };
};


export const log = (...args: any[]) => {
  console.log(...args);
};
export const dir = (obj: any) => {
  console.dir(obj);
};
export const di = (obj: any, maxDepth: number = 10) => {
  return util.inspect(obj, false, maxDepth, false);
};

function getWindowDimensions() {
  if (
    typeof window !== "undefined" &&
    typeof window.innerWidth !== "undefined"
  ) {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  } else return { width: 0, height: 0 };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
